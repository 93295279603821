<template lang="pug">
  b-row.mb-5
    b-col
      firmwares-form(:action='action')
</template>

<script>
import FirmwaresForm from '@views/container/manager/firmwares/Form'

export default {
  name: 'manager-firmwares-new',
  components: {
    FirmwaresForm,
  },
  props: {
    action: String,
  },
}
</script>
